/**
 * Created by jince on 2017/9/8.
 */
const tool = {
  isMobile() {
    var flag = false;
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    for (var i = 0; i < Agents.length; i++) {
      if (userAgentInfo.indexOf(Agents[i]) > 0) {
        flag = true;
        break;
      }
    }
    return flag;
  },
  setCookie: function (name, value, path, domain,day) {
    console.log('name11111', name, value, path, domain,day)
    console.log('document.cookie', document.cookie)

    // document.cookie.path = path;
    // document.cookie.setDomain = domain;
    var day = day || 0;
    var exp = new Date();
    exp.setTime(exp.getTime() + day * 24 * 60 * 60 * 1000);
    if (day == 0) {
      document.cookie = name + '=' + escape(value) + '; path=/;domain=' + domain;
    } else {
      document.cookie = name + '=' + escape(value) + '; path=/;domain=' + domain + ';expires=' + exp.toGMTString();
    }
  },
  getCookie: function (name) {
    var arr, reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
    if (arr = document.cookie.match(reg))
      return unescape(arr[2]);
    else
      return null;
  },
  delCookie: function (name, domain) {
    var date = new Date();
    date.setTime(date.getTime() - 10000);
    document.cookie = name + '=""; expires=' + date.toGMTString() + '; path=/;domain=' + domain;
  },
  jsonp: function (url) {
    return new Promise((resolve, reject) => {
      window.jsonCallBack = result => {
        resolve(result)
      }
      const JSONP = document.createElement('script');
      JSONP.type = 'text/javascript';
      JSONP.src = url;
      document.getElementsByTagName('head')[0].appendChild(JSONP);
      setTimeout(() => {
        document.getElementsByTagName('head')[0].removeChild(JSONP)
      },500)
    })
  }
}

export default tool

